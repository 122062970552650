<template>
  <el-container>
    <el-header>
      <h1>仪表盘</h1>
      <el-button type="primary" @click="logout" style="float: right;">退出登录</el-button>
    </el-header>

    <el-main>
      <!-- 信用分区域 -->
      <el-card style="margin-bottom: 20px;">
        <div class="credit-section">
          <!-- 信用分圆环和状态 -->
          <div class="credit-circle-container">
            <el-progress
              :percentage="creditScore"
              type="circle"
              :color="getCreditColor(creditScore)"
              stroke-width="10"
            >
              <p style="font-size: 2rem; font-weight: bold;" :style="{ color: getCreditColor(creditScore) }">
                {{ creditScore }}
              </p>
            </el-progress>
            <div class="credit-level">
              <h2>信用等级</h2>
              <p :style="{ color: getCreditColor(creditScore) }">{{ getCreditLevel(creditScore) }}</p>
            </div>
          </div>

          <!-- 信用分变更记录表 -->
          <el-table
            :data="scoreRecords"
            class="score-records"
            v-if="scoreRecords.length"
          >
            <el-table-column prop="datetime" label="变更时间" width="180"></el-table-column>
            <el-table-column prop="processor" label="处理人" width="120"></el-table-column>
            <el-table-column prop="score" label="变更分数" width="120">
              <template #default="scope">
                <span :style="{ color: scope.row.types > 0 ? '#67C23A' : '#F56C6C' }">
                  {{ scope.row.types > 0 ? '+' : '-' }}{{ scope.row.score }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="reason" label="变更原因"></el-table-column>
          </el-table>
        </div>
      </el-card>

      <!-- 统计数据区域 -->
      <el-row :gutter="20">
        <el-col :span="6" :xs="24" v-for="(value, key) in stats" :key="key">
          <el-card>
            <h2 style="margin-bottom: 30px;">{{ getStatLabel(key) }}</h2>
            <p>{{ value }}</p>
          </el-card>
        </el-col>
      </el-row>

      <!-- 公告信息区域 -->
      <el-card style="margin-top: 20px;">
        <h2 style="margin-bottom: 20px;">公告信息</h2>
        <el-table :data="announcements" style="width: 100%">
          <el-table-column prop="username" label="发布人" width="120"></el-table-column>
          <el-table-column prop="pushtime" label="发布时间" width="180"></el-table-column>
          <el-table-column prop="info" label="内容"></el-table-column>
        </el-table>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from '../axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'HomePage',
  setup() {
    const userInfo = ref({});
    const stats = ref({});
    const announcements = ref([]);
    const creditScore = ref(0); // 信用分
    const scoreRecords = ref([]); // 信用分变更记录

    const fetchUserInfo = () => {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=getUserInfos', { token })
        .then(response => {
          if (response.data.code === 200) {
            userInfo.value = response.data.user;
            creditScore.value = response.data.user.score || 0; // 获取信用分
          } else {
            ElMessage.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching user info:', error);
        });
    };

    const fetchStats = () => {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=getStats', { token })
        .then(response => {
          if (response.data.code === 200) {
            stats.value = response.data.stats;
          } else {
            ElMessage.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching stats:', error);
        });
    };

    const fetchAnnouncements = () => {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=getAnnouncements', { token })
        .then(response => {
          if (response.data.success) {
            announcements.value = response.data.announcements;
          } else {
            ElMessage.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching announcements:', error);
        });
    };

    const fetchScoreRecords = () => {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=getScoreRecords', { token, limit: 3 })
        .then(response => {
          if (response.data.success) {
            scoreRecords.value = response.data.records || [];
          } else {
            console.error('Error fetching score records:');
          }
        })
        .catch(error => {
          console.error('Error fetching score records:', error);
        });
    };

    const logout = () => {
      localStorage.removeItem('user-token');
      window.location.href = '/login';
    };

    const getStatLabel = (key) => {
      const labels = {
        totalCreateCards: '累计制卡次数',
        totalUsedCodes: '累计兑换码使用人次',
        todayCreateCards: '当天制卡次数',
        todayUsedCodes: '当天兑换码使用人次',
        todayRegisterUsers: '当天注册用户数量',
        totalRegisterUsers: '累计注册用户数量',
        totalBannedUsers: '累计封禁用户数量',
        totalPublishedGames: '累计发布游戏数量',
        totalOrders: '累计订单数量',
        todayOrdersPeople: '当天下单人次',
        todayOrdersOK: '当天付款人次',
        todayOrdersPrice: '已付订单总额',
        totalBannedCodes: '累计封卡次数'
      };
      return labels[key] || key;
    };

    const getCreditLevel = (score) => {
      if (score >= 85) return '信用优秀';
      if (score >= 70) return '信用良好';
      if (score >= 50) return '即将清退';
      return '您已被清退！';
    };

    const getCreditColor = (score) => {
      if (score >= 85) return '#67C23A'; // 绿色
      if (score >= 70) return '#E6A23C'; // 黄色
      if (score >= 50) return '#F56C6C'; // 橙色
      return '#909399'; // 灰色
    };

    onMounted(() => {
      fetchUserInfo();
      fetchStats();
      fetchAnnouncements();
      fetchScoreRecords();
    });

    return {
      userInfo,
      stats,
      announcements,
      creditScore,
      scoreRecords,
      fetchUserInfo,
      fetchStats,
      fetchAnnouncements,
      fetchScoreRecords,
      logout,
      getStatLabel,
      getCreditLevel,
      getCreditColor,
    };
  }
};
</script>
<style scoped>
.el-container {
  padding: 20px;
}
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.el-main {
  padding: 20px;
}
.el-card {
  margin-bottom: 20px;
}

/* 信用分区域布局 */
.credit-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.credit-circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.credit-level {
  /* margin-top: 20px; */
  text-align: center;
}
.credit-level h2 {
  font-size: 1.5rem;
  margin: 10px 0;
}
.credit-level p {
  font-size: 1.2rem;
}
.score-records {
  width: 50%; /* 默认电脑端表格宽度 */
  margin-left: 20px;
}

/* 针对移动端进行样式调整 */
@media screen and (max-width: 768px) {
  .credit-section {
    flex-direction: column;
    align-items: center;
  }
  .credit-circle-container {
    margin-bottom: 20px;
  }
  .credit-level {
    margin-top: 10px;
  }
  .credit-level h2 {
    font-size: 1.2rem; /* 缩小信用等级字体大小 */
  }
  .credit-level p {
    font-size: 1rem; /* 缩小信用等级描述字体大小 */
  }
  .score-records {
    width: 100%; /* 表格在手机端占满整个容器 */
    margin-left: 0;
    margin-top: 20px;
  }
}

/* 针对电脑端信用分状态和圆环在同一行 */
@media screen and (min-width: 769px) {
  .credit-circle-container {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1rem;
  }
  .credit-level {
    text-align: left;
    margin-left: 20px; /* 让状态信息与圆环保持适当间距 */
  }
}
</style>
