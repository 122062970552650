<template>
    <div class="LotteryRecord">
      <el-container>
        <el-header style="display: flex; align-items: center; justify-content: space-between;">
          <h1>抽奖记录</h1>
        </el-header>
        <el-main>
          <el-input
            v-model="searchQuery"
            placeholder="搜索用户名或奖品名"
            @input="fetchLotteryRecords"
            clearable
            prefix-icon="el-icon-search"
            style="margin-bottom: 20px;"
          ></el-input>
          <el-table :data="lotteryRecords" v-if="lotteryRecords.length > 0" style="width: 100%;" @sort-change="handleSortChange">
            <el-table-column prop="id" label="记录ID" width="150"></el-table-column>
            <el-table-column prop="name" label="奖品名" width="250"></el-table-column>
            <el-table-column prop="username" label="用户" width="200"></el-table-column>
            <el-table-column prop="time" label="中奖时间" width="200" sortable></el-table-column>
          </el-table>
          <div v-else>
            <el-empty description="没有数据可显示"></el-empty>
          </div>
          <el-pagination
            v-if="total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            style="margin-top: 20px; text-align: center;"
          ></el-pagination>
        </el-main>
      </el-container>
    </div>
  </template>
  
  <script>
  import axios from '../axios';
  import { ElMessage } from 'element-plus';
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  
  export default {
    name: 'LotteryRecord',
    setup() {
      const store = useStore();
      const owner = computed(() => store.state.user.owner);
      return {
        owner
      };
    },
    data() {
      return {
        lotteryRecords: [],  // 存储抽奖记录数据
        searchQuery: '',  // 存储搜索框内容
        currentPage: 1,  // 当前页数
        pageSize: 10,  // 每页显示记录数量
        total: 0,  // 总记录数
        sort: {
          prop: '',
          order: ''
        }
      };
    },
    created() {
      this.fetchLotteryRecords();
    },
    methods: {
      async fetchLotteryRecords() {
        try {
          const token = localStorage.getItem('user-token');
          const response = await axios.post('/index.php?action=getLotteryRecords', {
            token,
            search: this.searchQuery,
            page: this.currentPage,
            size: this.pageSize,
            sort: this.sort
          });
          this.lotteryRecords = response.data.records || [];
          this.total = parseInt(response.data.total, 10) || 0;
        } catch (error) {
          console.error('Error fetching lottery records:', error);
          ElMessage.error('获取抽奖记录失败');
        }
      },
      handleSortChange({ prop, order }) {
        this.sort = { prop, order };
        this.fetchLotteryRecords();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.currentPage = 1; // Reset to first page when page size changes
        this.fetchLotteryRecords();
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.fetchLotteryRecords();
      }
    }
  };
  </script>
  
  <style scoped>
  .LotteryRecord {
    padding: 20px;
  }
  
  .el-main {
    width: 100%;
  }
  
  .el-table {
    width: 100%;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    /* 移动端模态框全屏 */
    .el-dialog {
      width: 100% !important;
      margin: 0;
      padding: 0;
    }
  
    .el-dialog__body {
      padding: 20px;
    }
  
    .el-form-item {
      margin-bottom: 10px;
    }
  
    .el-dialog__footer {
      padding: 10px;
    }
  
    .el-input, .el-select {
      width: 100%;
    }
  
    .el-button {
      width: 100%;
      margin-top: 10px;
    }
  }
  </style>
  