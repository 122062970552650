<template>
    <el-container>
      <el-header>
        <h1>编辑经销商信用分</h1>
      </el-header>
  
      <el-main>
        <el-card>
          <el-form :model="form" label-width="120px">
            <!-- 经销商选择 -->
            <el-form-item label="选择经销商">
              <el-select
                v-model="form.distributor"
                placeholder="请选择经销商"
                :loading="loading"
                :disabled="loading"
              >
                <el-option
                v-for="distributor in distributors"
                :key="distributor.username"
                :label="`${distributor.username} (${distributor.TaobaoShop})`" 
                :value="distributor.username" 
                />
              </el-select>
            </el-form-item>
  
            <!-- 加减分选择 -->
            <el-form-item label="加减分选择">
              <el-radio-group v-model="form.action">
                <el-radio label="add">加分</el-radio>
                <el-radio label="subtract">减分</el-radio>
              </el-radio-group>
            </el-form-item>
  
            <!-- 加减分理由 -->
            <el-form-item label="变更理由">
              <el-select v-model="form.reason" placeholder="请选择理由">
                <el-option
                  v-for="reason in reasons"
                  :key="reason.value"
                  :label="reason.label"
                  :value="reason.value"
                />
              </el-select>
            </el-form-item>
  
            <!-- 分值选择 -->
            <el-form-item label="选择分值">
              <el-slider
                v-model="form.score"
                :min="1"
                :max="5"
                :step="1"
                show-stops
              />
            </el-form-item>
  
            <!-- 提交按钮 -->
            <el-form-item>
              <el-button type="primary" @click="submitForm" :loading="submitting">
                提交
              </el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-main>
    </el-container>
  </template>
  
  <script>
  import { ref, onMounted, watch } from "vue";
  import axios from "../axios";
  import { ElMessage } from "element-plus";
  
  export default {
    name: "EditDistributorScore",
    setup() {
      const form = ref({
        distributor: null, // 选择的经销商
        action: "add", // 加分或减分
        reason: null, // 变更理由
        score: 1, // 分值
      });
  
      const distributors = ref([]); // 经销商列表
      const reasons = ref([]); // 加减分理由
      const loading = ref(false); // 是否正在加载经销商列表
      const submitting = ref(false); // 是否正在提交表单
  
      // 获取经销商列表
      const fetchDistributors = async () => {
        loading.value = true;
        const token = localStorage.getItem("user-token");
        try {
          const response = await axios.post("/index.php?action=Distributors_list", {
            token,
          });
          if (response.data.success) {
            distributors.value = response.data.distributors || [];
          } else {
            ElMessage.error(response.data.message || "加载经销商列表失败");
          }
        } catch (error) {
          console.error("Error fetching distributors:", error);
          ElMessage.error("加载经销商列表失败");
        } finally {
          loading.value = false;
        }
      };
  
      // 提交表单
      const submitForm = async () => {
        if (!form.value.distributor || !form.value.reason) {
          ElMessage.error("请填写完整表单信息");
          return;
        }
  
        submitting.value = true;
        const token = localStorage.getItem("user-token");
        try {
          const response = await axios.post("/index.php?action=UpdateScore", {
            token,
            distributor: form.value.distributor,
            action: form.value.action,
            reason: form.value.reason,
            score: form.value.score,
          });
  
          if (response.data.success) {
            ElMessage.success("信用分变更成功");
            resetForm();
          } else {
            ElMessage.error(response.data.message || "信用分变更失败");
          }
        } catch (error) {
          console.error("Error submitting form:", error);
          ElMessage.error("信用分变更失败");
        } finally {
          submitting.value = false;
        }
      };
  
      // 重置表单
      const resetForm = () => {
        form.value = {
          distributor: null,
          action: "add",
          reason: null,
          score: 1,
        };
        updateReasons(); // 重置时也需要更新理由
      };
  
      // 根据加减分选择更新理由
      const updateReasons = () => {
        reasons.value =
          form.value.action === "add"
            ? [
                { label: "奖励优秀表现", value: "奖励优秀表现" },
                { label: "完成特殊任务", value: "完成特殊任务" },
              ]
            : [
                { label: "违反规定", value: "违反规定" },
                { label: "未达业绩目标", value: "未达业绩目标" },
              ];
      };
  
      // 监听加减分选择变化
      watch(
        () => form.value.action,
        () => {
          updateReasons();
          form.value.reason = null; // 切换加减分时清空选择的理由
        }
      );
  
      onMounted(() => {
        fetchDistributors();
        updateReasons();
      });
  
      return {
        form,
        distributors,
        reasons,
        loading,
        submitting,
        fetchDistributors,
        submitForm,
        resetForm,
        updateReasons,
      };
    },
  };
  </script>
  
  <style scoped>
   .el-container {
     padding: 20px;
   }
   .el-header {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 20px;
   }
   .el-main {
     padding: 20px;
   }
   .el-card {
     margin-bottom: 20px;
   }
  </style>
  