<template>
  <div class="GameList">
    <el-container>
      <el-header style="display: flex; align-items: center; justify-content: space-between;">
        <h1>游戏列表</h1>
        <el-button type="primary" @click="batchAddDialogVisible = true" style="margin-left: 20px;" v-if="owner === '0'">批量添加游戏</el-button>
      </el-header>
      <el-main>
        <el-input
          v-model="searchQuery"
          placeholder="搜索游戏ID或名称"
          @input="fetchGames"
          clearable
          prefix-icon="el-icon-search"
          style="margin-bottom: 20px;"
        ></el-input>
        <el-table :data="games" v-if="games.length > 0" style="width: 100%;" @sort-change="handleSortChange">
          <el-table-column prop="gameid" label="游戏ID" width="150"></el-table-column>
          <el-table-column prop="title" label="游戏名称" width="150"></el-table-column>
          <el-table-column prop="descor" label="描述" width="500"></el-table-column>
          <el-table-column prop="price" label="豪华版价格" width="100"></el-table-column>
          <el-table-column prop="super_price" label="终极版价格" width="100"></el-table-column>
          <el-table-column prop="postTime" label="上架时间" width="180" sortable v-if="owner === '0'"></el-table-column>
          <el-table-column prop="types" label="状态" width="100">
            <template #default="scope">
              <el-tag :type="scope.row.types === '0' ? 'success' : 'info'">
                {{ scope.row.types === '0' ? '正常' : '停售' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="category" label="分类" width="150"></el-table-column>
          <el-table-column prop="djiami" label="联机状态" width="150">
            <template #default="scope">
              <el-tag :type="scope.row.djiami === '0' ? 'gray' : (scope.row.djiami === '1' ? 'warning' : 'success')">
                {{ scope.row.djiami === '0' ? '单机' : (scope.row.djiami === '1' ? '联机' : '本体可联机') }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column v-if="owner === '0'" label="操作" width="180">
            <template #default="scope">
              <el-button @click="openEditDialog(scope.row)" type="primary" size="mini">编辑</el-button>
              <el-button @click="deleteGame(scope.row.gameid)" type="danger" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-else>
          <el-empty description="没有数据可显示"></el-empty>
        </div>
        <el-pagination
          v-if="total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="margin-top: 20px; text-align: center;"
        ></el-pagination>

        <!-- 编辑游戏的模态框 -->
        <el-dialog title="编辑游戏" v-model="dialogVisible" :fullscreen="isMobile" width="50%" @close="resetForm">
          <el-form :model="currentGame" label-width="100px" class="edit-form">
            <el-form-item label="游戏ID">
              <el-input v-model="currentGame.gameid" disabled></el-input>
            </el-form-item>
            <el-form-item label="游戏名称">
              <el-input v-model="currentGame.title"></el-input>
            </el-form-item>
            <el-form-item label="描述">
              <el-input type="textarea" v-model="currentGame.descor"></el-input>
            </el-form-item>
            <el-form-item label="封面图">
              <el-input v-model="currentGame.photo"></el-input>
            </el-form-item>
            <el-form-item label="豪华版价格">
              <el-input v-model="currentGame.price" type="number"></el-input>
            </el-form-item>
            <el-form-item label="终极版价格">
              <el-input v-model="currentGame.super_price" type="number"></el-input>
            </el-form-item>
            <el-form-item label="上架时间">
              <el-date-picker v-model="currentGame.postTime" type="datetime"></el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="currentGame.types">
                <el-option label="正常" value="0"></el-option>
                <el-option label="停售" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="离线账号">
              <el-input v-model="currentGame.username"></el-input>
            </el-form-item>
            <el-form-item label="离线密码">
              <el-input v-model="currentGame.password"></el-input>
            </el-form-item>
            <el-form-item label="分类">
              <el-select v-model="currentGame.category" placeholder="选择分类">
                <el-option v-for="category in categories" :key="category" :label="category" :value="category"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联机状态">
              <el-select v-model="currentGame.djiami">
                <el-option label="单机" value="0"></el-option>
                <el-option label="联机" value="1"></el-option>
                <el-option label="本体可联机" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <template #footer>
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveGame">保存</el-button>
          </template>
        </el-dialog>

        <!-- 批量添加游戏的对话框 -->
        <el-dialog title="批量添加游戏" v-model="batchAddDialogVisible" width="50%" :fullscreen="isMobile">
          <el-input
            type="textarea"
            v-model="batchGamesText"
            placeholder="每行一条游戏记录，字段用英文逗号分隔。顺序为：游戏ID, 游戏名称, 描述, 封面图, 豪华版价格, 终极版价格, 上架时间, 状态, 离线账号, 离线密码, 分类, 联机情况"
            rows="10"
          ></el-input>
          <template #footer>
            <el-button @click="batchAddDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="batchAddGames">提交</el-button>
          </template>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'GameList',
  setup() {
    const store = useStore();
    const owner = computed(() => store.state.user.owner);
    const isMobile = computed(() => window.innerWidth <= 768); // 判断是否为移动设备
    return {
      owner,
      isMobile
    };
  },
  data() {
    return {
      games: [],
      dialogVisible: false,
      batchAddDialogVisible: false, // 控制批量添加对话框的显示
      batchGamesText: '', // 存储批量添加的游戏数据
      currentGame: {
        gameid: '',
        title: '',
        descor: '',
        photo: '',
        price: '',
        super_price: '',
        postTime: '',
        types: '0',
        username: '',
        password: '',
        category: '', // 添加 category 字段
        djiami: ''
      },
      searchQuery: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      sort: {
        prop: '',
        order: ''
      },
      categories: ['休闲益智', '动作格斗', '角色扮演', '冒险解谜', '经营模拟', '射击游戏', '恐怖游戏'] // 添加分类数组
    };
  },
  created() {
    this.fetchGames();
  },
  methods: {
    async fetchGames() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getGames', { token, search: this.searchQuery, page: this.currentPage, size: this.pageSize, sort: this.sort });
        this.games = response.data.games || [];
        this.total = parseInt(response.data.total, 10) || 0;
      } catch (error) {
        console.error('Error fetching games:', error);
        ElMessage.error('获取游戏列表失败');
      }
    },
    handleSortChange({ prop, order }) {
      this.sort = { prop, order };
      this.fetchGames();
    },
    openEditDialog(game) {
      this.currentGame = { ...game };
      this.dialogVisible = true;
    },
    async saveGame() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=updateGame', {
          token,
          game: JSON.stringify(this.currentGame)
        });
        if (response.data.success) {
          ElMessage.success('游戏信息已更新');
          this.dialogVisible = false;
          this.fetchGames();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error updating game:', error);
        ElMessage.error('更新失败，请稍后重试');
      }
    },
    async deleteGame(gameid) {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=deleteGame', { token, gameid });
        if (response.data.success) {
          ElMessage.success('游戏已删除');
          this.fetchGames();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error deleting game:', error);
        ElMessage.error('删除失败，请稍后重试');
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1; // Reset to first page when page size changes
      this.fetchGames();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchGames();
    },
    resetForm() {
      this.currentGame = {
        gameid: '',
        title: '',
        descor: '',
        photo: '',
        price: '',
        super_price: '',
        postTime: '',
        types: '0',
        username: '',
        password: '',
        category: '', // 重置 category 字段
        djiami: ''
      };
    },
    async batchAddGames() {
      if (!this.batchGamesText.trim()) {
        ElMessage.error('请输入游戏数据');
        return;
      }

      const games = this.batchGamesText.trim().split('\n').map(line => {
        const [gameid, title, descor, photo, price, super_price, postTime, types, username, password, category, djiami] = line.split(',');
        return { gameid, title, descor, photo, price, super_price, postTime, types, username, password, category, djiami };
      });

      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=batchAddGames', {
          token,
          games: JSON.stringify(games)
        });

        if (response.data.success) {
          ElMessage.success('游戏批量添加成功');
          this.batchAddDialogVisible = false;
          this.fetchGames();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error batch adding games:', error);
        ElMessage.error('批量添加失败，请稍后重试');
      }
    }
  }
};
</script>

<style scoped>
.GameList {
  padding: 20px;
}

.el-main {
  width: 100%;
}

.el-table {
  width: 100%;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  /* 移动端模态框全屏 */
  .el-dialog {
    width: 100% !important;
    margin: 0;
    padding: 0;
  }

  .el-dialog__body {
    padding: 20px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .el-dialog__footer {
    padding: 10px;
  }

  .el-input, .el-select {
    width: 100%;
  }

  .el-button {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
