<template>
  <div class="dashboard">
    <!-- 控制面板 -->
    <div class="controls">
      <div class="card">
        <div class="card-header">
          <span>控制面板</span>
        </div>
        <div class="card-body">
          <el-form label-width="100px">
            <!-- 版本发布部分 -->
            <div class="form-section">
              <h4>发布新版本</h4>
              <el-form-item label="版本号">
                <el-input v-model="newVersion" placeholder="请输入版本号"></el-input>
              </el-form-item>
              <el-form-item label="下载URL">
                <el-input v-model="downloadUrl" placeholder="请输入下载URL"></el-input>
              </el-form-item>
              <el-button type="primary" @click="releaseNewVersion">发布版本</el-button>
            </div>

            <!-- 系统维护部分 -->
            <div class="form-section">
              <h4>系统维护</h4>
              <el-form-item label="维护状态">
                <div class="maintenance-control">
                  <el-switch v-model="isMaintenance"></el-switch>
                  <el-button type="primary" @click="toggleMaintenance" style="margin-left: 20px;">
                    {{ isMaintenance ? '关闭维护' : '开启维护' }}
                  </el-button>
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <!-- 轮播图设置 -->
    <div class="carousel-settings">
      <div class="card">
        <div class="card-header">
          <span>轮播图设置</span>
        </div>
        <div class="card-body">
          <el-form label-width="100px">
            <div class="form-section">
              <el-form-item label="图片URL">
                <el-input v-model="carousel.image" placeholder="请输入图片URL"></el-input>
              </el-form-item>
              <el-form-item label="标题">
                <el-input v-model="carousel.title" placeholder="请输入标题"></el-input>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="carousel.description" placeholder="请输入描述"></el-input>
              </el-form-item>
              <el-form-item label="游戏ID">
                <el-input v-model="carousel.gameid" placeholder="请输入gameid"></el-input>
              </el-form-item>
              <el-button type="primary" @click="addCarouselImage">添加轮播图</el-button>
            </div>

            <!-- 轮播图列表 -->
            <div class="carousel-list">
              <el-table :data="carouselImages" style="width: 100%;">
                <el-table-column prop="image" label="图片URL" min-width="200"></el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column prop="description" label="描述"></el-table-column>
                <el-table-column label="操作" width="100">
                  <template #default="scope">
                    <el-button 
                      @click="removeCarouselImage(scope.$index)" 
                      type="danger" 
                      size="small"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'UserDashboard',
  data() {
    return {
      newVersion: '',
      downloadUrl: '',
      isMaintenance: false,
      carousel: {
        image: '',
        title: '',
        description: '',
        gameid: ''
      },
      carouselImages: []
    };
  },
  methods: {
    async releaseNewVersion() {
      try {
        const token = localStorage.getItem('user-token');
        await axios.post('/index.php?action=releaseNewVersion', { 
          version: this.newVersion, 
          download_url: this.downloadUrl, 
          token 
        });
        ElMessage.success('新版本发布成功');
        this.newVersion = '';
        this.downloadUrl = '';
      } catch (error) {
        ElMessage.error('新版本发布失败');
      }
    },

    async toggleMaintenance() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=toggleMaintenance', { token });
        this.isMaintenance = response.data.newStatus === 1;
        ElMessage.success(`已${this.isMaintenance ? '开启' : '关闭'}维护模式`);
      } catch (error) {
        ElMessage.error('维护状态更新失败');
      }
    },

    async addCarouselImage() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=addCarouselImage', { 
          ...this.carousel, 
          token 
        });
        
        this.carouselImages.push({ ...this.carousel });
        this.carousel = { image: '', title: '', description: '', gameid: '' };
        ElMessage.success('轮播图添加成功');
      } catch (error) {
        ElMessage.error('轮播图添加失败');
      }
    },

    async removeCarouselImage(index) {

      const token = localStorage.getItem('user-token');
        const id = this.carouselImages[index].id;

        console.log(id);

      try {
        
        await axios.post('/index.php?action=DeleteCarouselImage', { 
          id,
          token 
        });
        
        this.carouselImages.splice(index, 1);
        ElMessage.success('轮播图删除成功');
      } catch (error) {
        ElMessage.error('轮播图删除失败');
      }
    },

    async getMaintenanceStatus() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getMaintenanceStatus', { token });
        this.isMaintenance = response.data.isMaintenance;
      } catch (error) {
        ElMessage.error('获取维护状态失败');
      }
    }
  },
  async created() {
    try {
      const token = localStorage.getItem('user-token');
      const response = await axios.post('/index.php?action=getCarouselImages', { token });
      this.carouselImages = response.data.carouselImages;
    } catch (error) {
      ElMessage.error('获取轮播图失败');
    }
    this.getMaintenanceStatus();
  }
};
</script>

<style scoped>
.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.card-header {
  padding: 16px 24px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
  font-weight: 500;
  color: #303133;
}

.card-body {
  padding: 24px;
}

.form-section {
  margin-bottom: 32px;
}

.form-section h4 {
  margin: 0 0 20px 0;
  color: #606266;
  font-size: 15px;
  font-weight: 500;
}

.maintenance-control {
  display: flex;
  align-items: center;
}

.carousel-list {
  margin-top: 24px;
}

.el-button + .el-button {
  margin-left: 12px;
}

@media (max-width: 768px) {
  .dashboard {
    padding: 10px;
  }
  
  .card {
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #ebeef5;
  }
  
  .card-body {
    padding: 16px;
  }
}
</style>