import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import axios from '../axios';

import HomePage from '../views/HomePage.vue';
import UserLogin from '../views/UserLogin.vue';
import Dashboard from '../views/Dashboard.vue';
import TodaySales from '../views/TodaySales.vue';
import SalesOverview from '../views/SalesOverview.vue';
import GenerateCode from '../views/GenerateCode.vue';
import GenerateCodeZS from '../views/GenerateCodeZS.vue';
import CodeRecords from '../views/CodeRecords.vue';
import CodeSettings from '../views/CodeSettings.vue';
import GameList from '../views/GameList.vue';
import NewGame from '../views/NewGame.vue';
import DepotsManagement from '../views/DepotsManagement.vue';
import InventoryUpload from '../views/InventoryUpload.vue';
import RegisteredUsers from '../views/RegisteredUsers.vue';
import ManagePermissions from '../views/ManagePermissions.vue';
import ManageDistributors from '../views/ManageDistributors.vue';
import AddDistributor from '../views/AddDistributor.vue';
import ManageAnnouncements from '../views/ManageAnnouncements.vue';
import ManageOrder from '../views/ManageOrder.vue';
import UpdateGames from '../views/UpdateGames.vue';
import GameDepots from '../views/GameDepots.vue';
import EditScore from '../views/EditDistributorScore';
import LotteryRecord from '../views/LotteryRecord.vue';

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/login', name: 'UserLogin', component: UserLogin, meta: { hideSidebar: true } },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/todaySales', name: 'TodaySales', component: TodaySales },
  { path: '/salesOverview', name: 'SalesOverview', component: SalesOverview },
  { path: '/generateCode', name: 'GenerateCode', component: GenerateCode },
  { path: '/generateCodeZS', name: 'GenerateCodeZS', component: GenerateCodeZS },
  { path: '/codeRecords', name: 'CodeRecords', component: CodeRecords },
  { path: '/codeSettings', name: 'CodeSettings', component: CodeSettings },
  { path: '/gameList', name: 'GameList', component: GameList },
  { path: '/newGame', name: 'NewGame', component: NewGame },
  { path: '/depotsManagement', name: 'DepotsManagement', component: DepotsManagement },
  { path: '/inventoryUpload', name: 'InventoryUpload', component: InventoryUpload },
  { path: '/registeredUsers', name: 'RegisteredUsers', component: RegisteredUsers },
  { path: '/permissions', name: 'ManagePermissions', component: ManagePermissions },
  { path: '/distributors', name: 'ManageDistributors', component: ManageDistributors },
  { path: '/addDistributor', name: 'AddDistributor', component: AddDistributor },
  { path: '/announcements', name: 'ManageAnnouncements', component: ManageAnnouncements },
  { path: '/order', name: 'ManageOrder', component: ManageOrder },
  { path: '/gameUpdate', name: 'UpdateGames', component: UpdateGames },
  { path: '/gameDepots', name: 'GameDepots', component: GameDepots },
  { path: '/editScore', name: 'EditScore', component: EditScore },
  { path: '/lotteryRecord', name: 'LotteryRecord', component: LotteryRecord },
  // Add other routes as necessary
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('user-token');
  const publicPages = ['UserLogin'];
  const authRequired = !publicPages.includes(to.name);

  if (!token && authRequired) {
    return next({ name: 'UserLogin' });
  }

  if (token) {
    try {
      const response = await axios.post('/index.php?action=getUserInfo', { token });
      if (response.data.code === 200) {
        localStorage.setItem('user-token', response.data.token); // 更新token
        const { types, owner, balance, createNum, balanceZS, createNumZS, promissID, } = response.data.user;

        store.dispatch('login', { username: response.data.user.username, types, owner, balance, createNum, balanceZS, createNumZS, promissID});

        // if (types === '1' && to.name !== 'block') {
        //   return next({ name: 'block' });
        // }

        // if (!SteamID && to.name !== 'linkSteam' && to.name !== 'block') {
        //   return next({ name: 'linkSteam' });
        // }
        
      } else {
        store.dispatch('logout'); // Token失效时触发登出逻辑
        return next({ name: 'UserLogin' });
      }
    } catch (error) {
      store.dispatch('logout'); // Token失效时触发登出逻辑
      return next({ name: 'UserLogin' });
    }
  }

  next();
});

export default router;